@import '~bootstrap/scss/bootstrap.scss';

:root {
  --plenoil-blue: #002f73;
  --plenoil-blue2: #4b50b9;
  --plenoil-orange: #ff7a00;
  --plenoil-orange2: #f05a23;
  --plenoil-yellow: #fcd937;
  --plenoil-yellow2: #e6aa41;
  --plenoil-black: #1e282d;
  --plenoil-black-shadow: rgba(30,40,45,.4);
  --plenoil-gray: #e6e6e6;
  --plenoil-gray2: #cdcdcd;
  --plenoil-green: #007961;
  --plenoil-green2: #23b473;
  --plenoil-white: #fff;
  --plenoil-red: #d23627;
  --plenoil-brown: #b96e50;
  --plenoil-shadow: 0px 0px 20px var(--plenoil-black-shadow);

  --plenoil-background: #fff2e7;
  --plenoil-background-gray: #f4f4f4;
}

html, body {
  font-family: Red Hat Display;
}

#root {
  width: 100%;
}

.text-plenoil-primary {
  color: var(--plenoil-blue2);
}

.bg-plenoil-primary {
  background: var(--plenoil-blue2);
}

.btn-plenoil {
	background: white;
	border-radius: 30px;
	font-size: 12px;
	padding: 10px 20px;
	font-weight: 600;
	border: 1px solid transparent;

	&:hover,
	&:active {
		opacity: 0.9;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&.btn-plenoil-white {
		background: white !important;
		color: var(--plenoil-black) !important;
	}

	&.btn-plenoil-orange {
		background: var(--plenoil-orange) !important;
		color: white !important;
	}

	&.btn-plenoil-blue2 {
		background: var(--plenoil-blue2) !important;
		color: white !important;

		&.outline {
			background: transparent !important;
			border-color: var(--plenoil-blue2) !important;
			color: var(--plenoil-blue2) !important;
		}
	}
}

input[type=number].no-arrows {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & {
    -moz-appearance: textfield;
  }
}


.box-plenoil {
	background: white;
	padding: 20px;
	border-radius: 20px;
	box-shadow: var(--plenoil-shadow);

	&.box-plenoil-padded {
		padding: 40px;
	}
}

.label-plenoil {
	font-weight: 600;
	font-size: 12px;
	padding-left: 20px;
	margin-bottom: 5px;
}

.input-plenoil {
	border: 1px solid var(--plenoil-black);
	padding: 8px 18px;
	border-radius: 30px;
	width: 100%;
	font-size: 13px;

	&.input-plenoil-blue2 {
		background: var(--plenoil-blue2);
		color: white;
	}
}

select.input-plenoil {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;

	background-image:
		linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position:
		calc(100% - 20px) calc(1em + 2px),
		calc(100% - 15px) calc(1em + 2px),
		calc(100% - .5em) .5em;
	background-size:
		5px 5px,
		5px 5px,
		1.5em 1.5em;
	background-repeat: no-repeat;
}
