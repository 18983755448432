.ticketPreview {
}

.ticketPreview .ticket {
	letter-spacing: 0px;
	font-size: 11px;
	font-weight: 400;
	margin: 0 auto;
	max-width: 350px;
}


.ticketPreview .ticket .outline {
	border: 0px solid var(--plenoil-orange2);
	transition: 0.2s;
}

.ticketPreview .ticket .outlined {
	padding: 2px 5px;
	border-radius: 5px;
	border-width: 2px;
}

.ticketPreview .ticket .ticketBody {
	color: #333;
	padding: 20px;
	width: 100%;
	border-radius: 5px;
	box-shadow: var(--plenoil-shadow);
}

.ticketPreview .ticket .ticketBody .section {

}

.ticketPreview .ticket .ticketBody .left {
	width: 55%;
} 

.ticketPreview .ticket .ticketBody .right {
	width: 45%;
}

.ticketPreview .ticket .ticketBody .right {
	text-align: right;
}

.ticketPreview .ticket .ticketBody .full {
	width: 100%;
}



.ticketPreview .ticket .ticketBody .first {
	display: flex;
}

.ticketPreview .ticket .ticketBody .first .stationName {
	font-weight: 500;
	font-size: 20px;
	text-transform: uppercase;
}

.ticketPreview .ticket .ticketBody .first .companyData {
	text-transform: uppercase;
}



.ticketPreview .ticket .ticketBody .second {
	margin-top: 10px;
	padding: 5px 0;
}

.ticketPreview .ticket .ticketBody .second .header {
	display: flex;
}

.ticketPreview .ticket .ticketBody .second .header .right {
	text-align: left;
}


.ticketPreview .ticket .ticketBody .second .body {
	display: flex;
	text-align: left;
}

.ticketPreview .ticket .ticketBody .second .body .left {
	max-width: 80px;
}


.ticketPreview .ticket .ticketBody .second .body .right {
	text-align: left;
}



.ticketPreview .ticket .ticketBody .third {
	margin-top: 10px;
	display: flex;
}

.ticketPreview .ticket .ticketBody .third .left {
	max-width: 80px;
}

.ticketPreview .ticket .ticketBody .third .right {
	text-align: left;
}



.ticketPreview .ticket .ticketBody .fourth {
	margin-top: 10px;
	display: flex;
}

.ticketPreview .ticket .ticketBody .fourth .left {
	width: 50%;
}

.ticketPreview .ticket .ticketBody .fourth .right {
	width: 50%;
	font-weight: bold;
}

.ticketPreview .ticket .ticketBody .last {
	margin-top: 10px;
	font-size: 12px;
}

.ticketPreview .ticket .ticketBody .last .realdecreto {
	font-size: 10px;
	margin-bottom: 10px;
}

.ticketPreview .ticket .ticketBody .last .taxes {
	font-size: 10px;
}

.ticketPreview .ticket .ticketBody .separator {
	width: 100%;
	height: 10px;
	overflow: hidden;
}



.ticketPreview .btn-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.ticketPreview .btn-orange {
	width: 180px;
	position: absolute;
	bottom: -15px;
	left: 50%;
	margin-left: -90px;
}